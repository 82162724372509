import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10e5d790 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _7eee2df5 = () => interopDefault(import('../pages/account-settings.vue' /* webpackChunkName: "pages/account-settings" */))
const _48bc40fe = () => interopDefault(import('../pages/actions/index.vue' /* webpackChunkName: "pages/actions/index" */))
const _75db658d = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _140db9ea = () => interopDefault(import('../pages/admind/index.vue' /* webpackChunkName: "pages/admind/index" */))
const _10882fd0 = () => interopDefault(import('../pages/apple.vue' /* webpackChunkName: "pages/apple" */))
const _1e53d554 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _5431c1d2 = () => interopDefault(import('../pages/building-owners.vue' /* webpackChunkName: "pages/building-owners" */))
const _17472eb6 = () => interopDefault(import('../pages/challenges/index.vue' /* webpackChunkName: "pages/challenges/index" */))
const _421ad20a = () => interopDefault(import('../pages/cities.vue' /* webpackChunkName: "pages/cities" */))
const _2dbd1b19 = () => interopDefault(import('../pages/companies.vue' /* webpackChunkName: "pages/companies" */))
const _407bdfc4 = () => interopDefault(import('../pages/earthday.vue' /* webpackChunkName: "pages/earthday" */))
const _0fa074e3 = () => interopDefault(import('../pages/ecogroups.vue' /* webpackChunkName: "pages/ecogroups" */))
const _3d60441c = () => interopDefault(import('../pages/explore-challenges.vue' /* webpackChunkName: "pages/explore-challenges" */))
const _35997178 = () => interopDefault(import('../pages/facebook.vue' /* webpackChunkName: "pages/facebook" */))
const _7c06c84f = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _8e4c4122 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _0e13b4a7 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0173e8bc = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _8e9caaee = () => interopDefault(import('../pages/my-meters.vue' /* webpackChunkName: "pages/my-meters" */))
const _111037f2 = () => interopDefault(import('../pages/my-usage.vue' /* webpackChunkName: "pages/my-usage" */))
const _12b6aefc = () => interopDefault(import('../pages/new_sign_up.vue' /* webpackChunkName: "pages/new_sign_up" */))
const _15c139e6 = () => interopDefault(import('../pages/old_actions/index.vue' /* webpackChunkName: "pages/old_actions/index" */))
const _49fde6bd = () => interopDefault(import('../pages/old_challenges/index.vue' /* webpackChunkName: "pages/old_challenges/index" */))
const _69cc7278 = () => interopDefault(import('../pages/old_index.vue' /* webpackChunkName: "pages/old_index" */))
const _3374158f = () => interopDefault(import('../pages/old_login.vue' /* webpackChunkName: "pages/old_login" */))
const _6d5ecd5c = () => interopDefault(import('../pages/old_syncdata/index.vue' /* webpackChunkName: "pages/old_syncdata/index" */))
const _5eafb8db = () => interopDefault(import('../pages/old-account-settings.vue' /* webpackChunkName: "pages/old-account-settings" */))
const _062608d8 = () => interopDefault(import('../pages/old-my-usage.vue' /* webpackChunkName: "pages/old-my-usage" */))
const _6e93cc56 = () => interopDefault(import('../pages/organizers.vue' /* webpackChunkName: "pages/organizers" */))
const _02ef2c76 = () => interopDefault(import('../pages/pilot.vue' /* webpackChunkName: "pages/pilot" */))
const _256dbd04 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _e845246e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5cdbef86 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _6b209c55 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _3f88b44d = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _1493158c = () => interopDefault(import('../pages/syncdata/index.vue' /* webpackChunkName: "pages/syncdata/index" */))
const _1f054f70 = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _6b2b9dd5 = () => interopDefault(import('../pages/admind/background_tasks.vue' /* webpackChunkName: "pages/admind/background_tasks" */))
const _0f15e40b = () => interopDefault(import('../pages/admind/challenges/index.vue' /* webpackChunkName: "pages/admind/challenges/index" */))
const _c9f76f98 = () => interopDefault(import('../pages/admind/email_events.vue' /* webpackChunkName: "pages/admind/email_events" */))
const _c140df8a = () => interopDefault(import('../pages/admind/organizations/index.vue' /* webpackChunkName: "pages/admind/organizations/index" */))
const _68e79e4f = () => interopDefault(import('../pages/admind/pelm_energy_accounts/index.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/index" */))
const _622637f0 = () => interopDefault(import('../pages/admind/pelm_subscriptions/index.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/index" */))
const _46bdd1a0 = () => interopDefault(import('../pages/admind/property/index.vue' /* webpackChunkName: "pages/admind/property/index" */))
const _5a8bbcd1 = () => interopDefault(import('../pages/admind/teams/index.vue' /* webpackChunkName: "pages/admind/teams/index" */))
const _21e6faba = () => interopDefault(import('../pages/admind/users/index.vue' /* webpackChunkName: "pages/admind/users/index" */))
const _f588b52c = () => interopDefault(import('../pages/admind/utility_providers/index.vue' /* webpackChunkName: "pages/admind/utility_providers/index" */))
const _8236128e = () => interopDefault(import('../pages/challenges/create.vue' /* webpackChunkName: "pages/challenges/create" */))
const _663921aa = () => interopDefault(import('../pages/old_account-settings/account.vue' /* webpackChunkName: "pages/old_account-settings/account" */))
const _58d28407 = () => interopDefault(import('../pages/old_account-settings/meter.vue' /* webpackChunkName: "pages/old_account-settings/meter" */))
const _44ce1def = () => interopDefault(import('../pages/old_account-settings/meter_old.vue' /* webpackChunkName: "pages/old_account-settings/meter_old" */))
const _4fac4034 = () => interopDefault(import('../pages/old_account-settings/notifications.vue' /* webpackChunkName: "pages/old_account-settings/notifications" */))
const _8c26f162 = () => interopDefault(import('../pages/old_account-settings/team.vue' /* webpackChunkName: "pages/old_account-settings/team" */))
const _7951beea = () => interopDefault(import('../pages/old_account-settings/utility.vue' /* webpackChunkName: "pages/old_account-settings/utility" */))
const _1d733ba1 = () => interopDefault(import('../pages/old_challenges/create.vue' /* webpackChunkName: "pages/old_challenges/create" */))
const _f8d6430a = () => interopDefault(import('../pages/signup/confirm.vue' /* webpackChunkName: "pages/signup/confirm" */))
const _26ab4058 = () => interopDefault(import('../pages/signup/confirm_email.vue' /* webpackChunkName: "pages/signup/confirm_email" */))
const _6c2f6eb5 = () => interopDefault(import('../pages/signup/old_index.vue' /* webpackChunkName: "pages/signup/old_index" */))
const _2815df8a = () => interopDefault(import('../pages/signup/send-verification.vue' /* webpackChunkName: "pages/signup/send-verification" */))
const _7446c1e3 = () => interopDefault(import('../pages/admind/organizations/create.vue' /* webpackChunkName: "pages/admind/organizations/create" */))
const _ff6a8d14 = () => interopDefault(import('../pages/admind/challenges/_challengeId.vue' /* webpackChunkName: "pages/admind/challenges/_challengeId" */))
const _77d49554 = () => interopDefault(import('../pages/admind/pelm_energy_accounts/_energyAccountId.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/_energyAccountId" */))
const _0d31106d = () => interopDefault(import('../pages/admind/pelm_subscriptions/_subscriptionId.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/_subscriptionId" */))
const _137846cd = () => interopDefault(import('../pages/admind/property/_propertyId.vue' /* webpackChunkName: "pages/admind/property/_propertyId" */))
const _a9ac3d94 = () => interopDefault(import('../pages/admind/teams/_teamId.vue' /* webpackChunkName: "pages/admind/teams/_teamId" */))
const _4ac84d14 = () => interopDefault(import('../pages/admind/users/_userId.vue' /* webpackChunkName: "pages/admind/users/_userId" */))
const _d01db518 = () => interopDefault(import('../pages/admind/utility_providers/_utilId.vue' /* webpackChunkName: "pages/admind/utility_providers/_utilId" */))
const _380f8fd6 = () => interopDefault(import('../pages/challenges/rules/_rule.vue' /* webpackChunkName: "pages/challenges/rules/_rule" */))
const _130ba429 = () => interopDefault(import('../pages/admind/organizations/_organizationId/details.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/details" */))
const _1a5cae9c = () => interopDefault(import('../pages/admind/organizations/_organizationId/update.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/update" */))
const _0eb3108b = () => interopDefault(import('../pages/actions/_tipName.vue' /* webpackChunkName: "pages/actions/_tipName" */))
const _54e303be = () => interopDefault(import('../pages/challenges/_challenge.vue' /* webpackChunkName: "pages/challenges/_challenge" */))
const _64548ea3 = () => interopDefault(import('../pages/old_actions/_tipName.vue' /* webpackChunkName: "pages/old_actions/_tipName" */))
const _64523137 = () => interopDefault(import('../pages/old_syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/old_syncdata/_subscriptionId" */))
const _aab769c2 = () => interopDefault(import('../pages/syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/syncdata/_subscriptionId" */))
const _48ab40a0 = () => interopDefault(import('../pages/old_challenges/_challenge/_challenge_slug.vue' /* webpackChunkName: "pages/old_challenges/_challenge/_challenge_slug" */))
const _446c1190 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _10e5d790,
    name: "about-us"
  }, {
    path: "/account-settings",
    component: _7eee2df5,
    name: "account-settings"
  }, {
    path: "/actions",
    component: _48bc40fe,
    name: "actions"
  }, {
    path: "/admin",
    component: _75db658d,
    name: "admin"
  }, {
    path: "/admind",
    component: _140db9ea,
    name: "admind"
  }, {
    path: "/apple",
    component: _10882fd0,
    name: "apple"
  }, {
    path: "/blog",
    component: _1e53d554,
    name: "blog"
  }, {
    path: "/building-owners",
    component: _5431c1d2,
    name: "building-owners"
  }, {
    path: "/challenges",
    component: _17472eb6,
    name: "challenges"
  }, {
    path: "/cities",
    component: _421ad20a,
    name: "cities"
  }, {
    path: "/companies",
    component: _2dbd1b19,
    name: "companies"
  }, {
    path: "/earthday",
    component: _407bdfc4,
    name: "earthday"
  }, {
    path: "/ecogroups",
    component: _0fa074e3,
    name: "ecogroups"
  }, {
    path: "/explore-challenges",
    component: _3d60441c,
    name: "explore-challenges"
  }, {
    path: "/facebook",
    component: _35997178,
    name: "facebook"
  }, {
    path: "/faqs",
    component: _7c06c84f,
    name: "faqs"
  }, {
    path: "/how-it-works",
    component: _8e4c4122,
    name: "how-it-works"
  }, {
    path: "/login",
    component: _0e13b4a7,
    name: "login"
  }, {
    path: "/logout",
    component: _0173e8bc,
    name: "logout"
  }, {
    path: "/my-meters",
    component: _8e9caaee,
    name: "my-meters"
  }, {
    path: "/my-usage",
    component: _111037f2,
    name: "my-usage"
  }, {
    path: "/new_sign_up",
    component: _12b6aefc,
    name: "new_sign_up"
  }, {
    path: "/old_actions",
    component: _15c139e6,
    name: "old_actions"
  }, {
    path: "/old_challenges",
    component: _49fde6bd,
    name: "old_challenges"
  }, {
    path: "/old_index",
    component: _69cc7278,
    name: "old_index"
  }, {
    path: "/old_login",
    component: _3374158f,
    name: "old_login"
  }, {
    path: "/old_syncdata",
    component: _6d5ecd5c,
    name: "old_syncdata"
  }, {
    path: "/old-account-settings",
    component: _5eafb8db,
    name: "old-account-settings"
  }, {
    path: "/old-my-usage",
    component: _062608d8,
    name: "old-my-usage"
  }, {
    path: "/organizers",
    component: _6e93cc56,
    name: "organizers"
  }, {
    path: "/pilot",
    component: _02ef2c76,
    name: "pilot"
  }, {
    path: "/pricing",
    component: _256dbd04,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _e845246e,
    name: "privacy-policy"
  }, {
    path: "/reset_password",
    component: _5cdbef86,
    name: "reset_password"
  }, {
    path: "/settings",
    component: _6b209c55,
    name: "settings"
  }, {
    path: "/signup",
    component: _3f88b44d,
    name: "signup"
  }, {
    path: "/syncdata",
    component: _1493158c,
    name: "syncdata"
  }, {
    path: "/terms-conditions",
    component: _1f054f70,
    name: "terms-conditions"
  }, {
    path: "/admind/background_tasks",
    component: _6b2b9dd5,
    name: "admind-background_tasks"
  }, {
    path: "/admind/challenges",
    component: _0f15e40b,
    name: "admind-challenges"
  }, {
    path: "/admind/email_events",
    component: _c9f76f98,
    name: "admind-email_events"
  }, {
    path: "/admind/organizations",
    component: _c140df8a,
    name: "admind-organizations"
  }, {
    path: "/admind/pelm_energy_accounts",
    component: _68e79e4f,
    name: "admind-pelm_energy_accounts"
  }, {
    path: "/admind/pelm_subscriptions",
    component: _622637f0,
    name: "admind-pelm_subscriptions"
  }, {
    path: "/admind/property",
    component: _46bdd1a0,
    name: "admind-property"
  }, {
    path: "/admind/teams",
    component: _5a8bbcd1,
    name: "admind-teams"
  }, {
    path: "/admind/users",
    component: _21e6faba,
    name: "admind-users"
  }, {
    path: "/admind/utility_providers",
    component: _f588b52c,
    name: "admind-utility_providers"
  }, {
    path: "/challenges/create",
    component: _8236128e,
    name: "challenges-create"
  }, {
    path: "/old_account-settings/account",
    component: _663921aa,
    name: "old_account-settings-account"
  }, {
    path: "/old_account-settings/meter",
    component: _58d28407,
    name: "old_account-settings-meter"
  }, {
    path: "/old_account-settings/meter_old",
    component: _44ce1def,
    name: "old_account-settings-meter_old"
  }, {
    path: "/old_account-settings/notifications",
    component: _4fac4034,
    name: "old_account-settings-notifications"
  }, {
    path: "/old_account-settings/team",
    component: _8c26f162,
    name: "old_account-settings-team"
  }, {
    path: "/old_account-settings/utility",
    component: _7951beea,
    name: "old_account-settings-utility"
  }, {
    path: "/old_challenges/create",
    component: _1d733ba1,
    name: "old_challenges-create"
  }, {
    path: "/signup/confirm",
    component: _f8d6430a,
    name: "signup-confirm"
  }, {
    path: "/signup/confirm_email",
    component: _26ab4058,
    name: "signup-confirm_email"
  }, {
    path: "/signup/old_index",
    component: _6c2f6eb5,
    name: "signup-old_index"
  }, {
    path: "/signup/send-verification",
    component: _2815df8a,
    name: "signup-send-verification"
  }, {
    path: "/admind/organizations/create",
    component: _7446c1e3,
    name: "admind-organizations-create"
  }, {
    path: "/admind/challenges/:challengeId",
    component: _ff6a8d14,
    name: "admind-challenges-challengeId"
  }, {
    path: "/admind/pelm_energy_accounts/:energyAccountId",
    component: _77d49554,
    name: "admind-pelm_energy_accounts-energyAccountId"
  }, {
    path: "/admind/pelm_subscriptions/:subscriptionId",
    component: _0d31106d,
    name: "admind-pelm_subscriptions-subscriptionId"
  }, {
    path: "/admind/property/:propertyId",
    component: _137846cd,
    name: "admind-property-propertyId"
  }, {
    path: "/admind/teams/:teamId",
    component: _a9ac3d94,
    name: "admind-teams-teamId"
  }, {
    path: "/admind/users/:userId",
    component: _4ac84d14,
    name: "admind-users-userId"
  }, {
    path: "/admind/utility_providers/:utilId",
    component: _d01db518,
    name: "admind-utility_providers-utilId"
  }, {
    path: "/challenges/rules/:rule?",
    component: _380f8fd6,
    name: "challenges-rules-rule"
  }, {
    path: "/admind/organizations/:organizationId/details",
    component: _130ba429,
    name: "admind-organizations-organizationId-details"
  }, {
    path: "/admind/organizations/:organizationId/update",
    component: _1a5cae9c,
    name: "admind-organizations-organizationId-update"
  }, {
    path: "/actions/:tipName",
    component: _0eb3108b,
    name: "actions-tipName"
  }, {
    path: "/challenges/:challenge",
    component: _54e303be,
    name: "challenges-challenge"
  }, {
    path: "/old_actions/:tipName",
    component: _64548ea3,
    name: "old_actions-tipName"
  }, {
    path: "/old_syncdata/:subscriptionId",
    component: _64523137,
    name: "old_syncdata-subscriptionId"
  }, {
    path: "/syncdata/:subscriptionId",
    component: _aab769c2,
    name: "syncdata-subscriptionId"
  }, {
    path: "/old_challenges/:challenge/:challenge_slug?",
    component: _48ab40a0,
    name: "old_challenges-challenge-challenge_slug"
  }, {
    path: "/",
    component: _446c1190,
    name: "index"
  }, {
    path: "/confirm_email",
    component: _26ab4058
  }, {
    path: "/send-verification",
    component: _2815df8a
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
